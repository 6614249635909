<template>
	<Transition name="slide-right">
		<div
			v-if="showSlider"
			class="absolute z-20 w-full h-full p-4 bg-white"
		>
			<ul class="z-30 flex flex-col w-full pt-2 pb-8 space-y-6 text-2xl">
				<li
					v-for="value in categories"
					:key="value.text"
					class="w-full"
				>
					<button
						class="flex w-full font-semibold capitalize"
						@click="handleRouteChange(value.to)"
					>
						<span class="pl-[60px]">
							{{ value.text }}
						</span>
					</button>
				</li>
			</ul>
		</div>
	</Transition>
</template>

<script async>
export default {
	props: {
		categories: {
			type: Array,
			required: true
		},
		showCategories: {
			type: Boolean,
			default: false
		},
		isMobile: {
			type: Boolean,
			default: false
		},
		sectionPath: {
			type: String,
			required: true
		}
	},
	emits: [ 'close-slider' ],
	computed: {
		showSlider() {
			return this.showCategories && this.isMobile
		}
	},
	methods: {
		handleRouteChange(to) {
			this.$router.push(to).then(nextRoute => {
				this.$emit('close-slider')
			})
		}
	}
}
</script>
